import React, { useState, useEffect, useRef } from "react"
import {navigate} from "gatsby";
import { motion } from "framer-motion";
import { AiOutlineRight } from 'react-icons/ai';
import {AnimatePresence} from "framer-motion";
import {useIsMobile} from "../utils";

const Promoter = ({
  promotions
}) => {
  const [index, setIndex] = useState(0);
  const { isMobile } = useIsMobile();
  const variants = {
      visible: {
          opacity: 1,
          y: 0,
          transition: {
            delay: 1,
            duration: 0.5,
          },
          height: 'auto',
      },
      initial: {
          opacity: 0,
          height: 0,
          y: 60,
      },
      exit: {
          opacity: 0,
          y: -60,
          height: 0,
          transition: {
            duration: 0.5,
          }
      },
  };
  const motionProps = {
      animate: 'visible',
      initial: 'initial',
      exit: 'exit',
      variants,
  }

  const next = () => {
    setIndex((index + 1) % promotions.length);
  }

  const playRef = useRef(null);
  playRef.current = next;

  useEffect(() => {
    let timer = setInterval(() => playRef.current(), 6000);
    return () =>  clearInterval(timer);
  }, [])

  return (
    <div
      style={{
        background: '#8E6549',
        color: 'white',
        width: '100%',
        padding: '12px 48px',
        display: 'inline-block',
        textAlign: 'center',
        position: 'relative',
        minHeight: 48,
        overflow: 'hidden',
        marginBottom: '-5px'
      }}
    >
      <AnimatePresence>
      {
        promotions.map((promotion, i) => (
          <motion.div
            key={`promotion-${i}`}
            style={{
//              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              ...(promotion.link && {
                cursor: 'pointer',
              }) 
            }}
            onClick={() => {
              if (promotion.link) {
                navigate(promotion.link);
              }
            }}
            {...motionProps}
          >
            {promotion.description}
          </motion.div>
        )).filter((_, i) => i === index)
      }
      </AnimatePresence>
      <div
        style={{
          background: '#8E6549',
          position: 'absolute',
          right: 0,
          width: 48,
          top: 10,
          cursor: 'pointer',
        }}
        onClick={() => {
          next();
        }}
      >
        <AiOutlineRight />
      </div>
    </div>
  );
}

export default Promoter;
